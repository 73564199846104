import React from "react";
import LayoutSimple from "../components/layout/layoutSimple";
import Seo from "../components/layout/seo";

const SPVList = () => {
    return (
        <LayoutSimple>
            <Seo title="SPV List" />
            <div className="flex justify-center w-full mt-12 p-8">
                <div className="max-w-xl">
                    <div className="mt-10 text-justify">
                        <h1 className="text-3xl font-semibold py-2">SPV List</h1>
                        <p className="italic py-4">Updated February 2023</p>
                        <p>
                            No SPVs formed yet.
                        </p>
                    </div>
                </div>
            </div>
        </LayoutSimple>
    );
};
export default SPVList;
